import React, { useEffect, useState } from 'react'
import { requestForToken, onMessageListener } from '../firebase';
import { IonToast } from '@ionic/react';

const Notification: React.FC = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [showToast, setShowToast] = useState(false);
  const button = [
    {
      text: 'Entendido',
      role: 'cancel',
    }
  ]

  requestForToken();
  onMessageListener()
    .then((payload: any) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
      setShowToast(true);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={`${notification.title}: ${notification.body}`}
        position='top'
        duration={10000}
        buttons={button}
      />
    </>
  )
}

export default Notification