import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAZr25bZALn2UUmcVXcoxSbPSr_SPznvEM",
  authDomain: "cym-internet.firebaseapp.com",
  projectId: "cym-internet",
  storageBucket: "cym-internet.appspot.com",
  messagingSenderId: "370549390132",
  appId: "1:370549390132:web:e4b67538ff26c54dbec361",
  measurementId: "G-EVMGZGDCSB"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(firebaseApp);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: "BN3yZ2BCYtJcitMX285eYRdCRO5F6GCPUS3QyzIIZ0YW5tSKVWILLQUXptrEgExJGLFJKBMlB5FPJ73sqA6rUVE" })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        //send token to your app server and store it using your preferred method
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: any) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });
