import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab2.css';
import Notification from '../components/Notification';

const Tab2: React.FC = () => {

  return (
    <IonPage>
      <Notification />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Novedades</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Novedades</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name="Aqui estaran las novedades sobre tu servicio" />
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
